/* Google Fonts Montserrat */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

/* Genel Ayarlar */
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #1e1e1e;
  color: #ffffff;
}

.contact-page {
  padding: 20px;
}

/* Header Styles */
.header {
  display: flex;
  flex-direction: column; /* İçerikleri dikey hizala */
  align-items: center; /* Tüm öğeleri ortala */
  justify-content: center; /* Logoyu tam ortala */
  margin-top: 0px;
  background-color: transparent;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 20px; /* Kenarlardan içeriklere boşluk bırak */
}

.logo-container {
  display: flex;
  justify-content: center; /* Logoyu yatay ortala */
  align-items: center; /* Logoyu dikey ortala */
}

.logo {
  height: 85px; /* Desktop için logo boyutu */
  width: auto;
}

/* Homepage button styled like the given header.css */
.homepage-button {
  position: absolute; /* Butonu header içinde sabit tut */
  right: 20px; /* Sağdan boşluk */
  top: 20px; /* Yukarıdan boşluk */
  display: inline-flex;
  padding: 7px 45px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.homepage-button:hover {
  background-color: #ffffff;
  color: #202020;
  transform: translateY(-6px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.homepage-button:active {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Kartlar */
.contact-section {
  text-align: center;
  margin-top: 19px;
  font-family: "Montserrat", sans-serif;
  padding-left: 10px;
}

.contact-cards {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
  padding: 15px;
  font-family: "Montserrat", sans-serif;
  padding-left: 10px;
}

.contact-card {
  width: 348px;
  height: 240px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid rgba(217, 217, 217, 0.5);
  background: #202020;
  padding: 5px;
  box-sizing: border-box;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  font-family: "Montserrat", sans-serif;
  padding-left: 15px;
}

.contact-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.contact-card h3 {
  color: #ffffff;
  font-size: 1.1rem;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
}

.contact-card p {
  color: #d9d9d9;
  line-height: 1.3;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
}

.contact-card a {
  color: #d9d9d9;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
}

.contact-card a:hover {
  text-decoration: underline;
}

.social-icons {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 15px;
}

.social-icons a {
  color: #d9d9d980;
  font-size: 1.3rem;
  transition: color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
}

.social-icons a:hover {
  color: #ffffff;
  transform: scale(1.1);
}

/* Form Bölümü */
.contact-form-section {
  text-align: center;
  margin-top: 30px;
  font-family: "Montserrat", sans-serif;
}

.contact-form-section h2 {
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem; /* Adjust as needed */
}

.text-white {
  color: #ffffff; /* White color for "Contact" */
}

.text-blue {
  color: #2196f3; /* Blue color for "Form" */
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 800px;
  margin: 0 auto;
  font-family: "Montserrat", sans-serif;
}

.form-columns {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.form-column {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
  min-width: 250px;
}

.form-message {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #444;
  border-radius: 9px;
  background-color: #292929;
  color: #ffffff;
  font-size: 1rem;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.contact-form textarea {
  height: 200px;
  font-family: "Montserrat", sans-serif;
}

.form-submit {
  display: flex;
  justify-content: flex-end;
  font-family: "Montserrat", sans-serif;
}

.contact-form button {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 9px 35px;
  cursor: pointer;
  border-radius: 9px;
  font-family: "Montserrat", sans-serif;
  transition: background-color 0.3s, color 0.3s;
}

.contact-form button:hover {
  background-color: #edeff0;
  transform: translateY(-3px);
  color: #000000;
}

.contact-form button:active {
  transform: translateY(1px);
}

.contact-page-footer {
  margin-top: 50px;
}
@media (max-width: 768px) {
  .logo {
    height: 40px;
    width: auto;
  }

  .homepage-button {
    position: static;
    margin-top: 10px;
    width: 100%;
    max-width: 300px;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
  }

  .contact-cards {
    flex-direction: column;
    align-items: center;
  }
}

.form-submit {
  justify-content: center; /* Center the button on mobile */
}

.contact-form button {
  padding: 12px 50px; /* Slightly larger padding */
  font-size: 18px; /* Slightly larger font size */
}
