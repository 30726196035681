.container-card {
  display: flex;
  flex-wrap: wrap; /* Kartlar taşarsa alt satıra geç */
  gap: 24px; /* Kartlar arasındaki boşluk */
  justify-content: center; /* Kartları yatayda ortala */
  align-items: flex-start; /* Kartları dikeyde hizala */
  padding: 20px; /* Kenarlardan boşluk */
  box-sizing: border-box;
}

/* Kart butonlarının düzeni */
.card-buttons-outside {
  display: flex; /* Flexbox etkinleştirildi */
  justify-content: center; /* Yatayda ortala */
  align-items: center; /* Dikeyde hizala */
  gap: 20px; /* Butonlar arasındaki boşluk */
  margin-top: 20px; /* Kart ile butonlar arasında boşluk */
  width: 100%; /* Butonlar yatayda tam genişlik alır */
}

/* Kartın üstten ortalanması */
.card-focus {
  position: relative;
  margin-top: auto; /* Üstten boşluk otomatik */
  margin-bottom: auto; /* Alttan boşluk otomatik */
  width: 348px;
  height: 344px;
  overflow: hidden; /* Taşan içerikleri gizler */
  border-radius: 10px;
  border: 2px solid rgba(217, 217, 217, 0.5);
  background: #202020;
  box-sizing: border-box;
  transition: transform 0.3s;

  .card-focus {
    padding: 20px; /* Kartın iç kenarlarından boşluk ekle */
    box-sizing: border-box; /* Padding’i kartın boyutuna dahil et */
  }

  .container-card {
    gap: 20px; /* Kartlar arasındaki boşluğu artır */
    margin-bottom: 20px; /* Alt kısımda daha fazla boşluk bırak */
  }
}

@media (max-width: 1130px) {
  .container-card {
    display: flex;
    flex-direction: column; /* Kartları dikey sırala */
    align-items: center; /* Kartları yatayda ortala */
    gap: 20px; /* Kartlar arasındaki boşluğu artır */
    padding: 10px; /* Kenarlardan iç boşluk ekle */
    width: 100%; /* Tüm alanı kullan */
  }
  .card-focus {
    width: 90%; /* Kart genişliğini ekranın %90'ına ayarla */
    margin: 0 auto; /* Kartları yatayda ortala */
  }

  .card-buttons-outside {
    display: flex;
    justify-content: center; /* Butonları yatayda ortala */
    margin-top: 10px; /* Butonlar ile kart arasında boşluk bırak */
  }

  .card-button-outside {
    width: 80%; /* Buton genişliğini azalt */
    padding: 10px; /* Daha rahat bir boyut için padding */
  }
}
