/* Genel Stil */
.header {
  display: flex;
  flex-direction: column; /* İçerikleri dikey hizala */
  align-items: center; /* Tüm öğeleri ortala */
  justify-content: center; /* Logoyu tam ortala */
  margin-top: 0px;
  background-color: transparent;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 20px; /* Kenarlardan içeriklere boşluk bırak */
}

.logo-container {
  display: flex;
  justify-content: center; /* Logoyu yatay ortala */
  align-items: center; /* Logoyu dikey ortala */
}

.logo {
  height: 85px; /* Desktop için logo boyutu */
  width: auto;
}

.contact-button {
  position: absolute; /* Butonu header içinde sabit tut */
  right: 20px; /* Sağdan boşluk */
  top: 20px; /* Yukarıdan boşluk */
  display: inline-flex;
  padding: 7px 45px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 18px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-button:hover {
  background-color: #ffffff;
  color: #202020;
  transform: translateY(-6px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.contact-button:active {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Mobil Stil */
@media (max-width: 768px) {
  .header {
    padding: 20px 10px;
  }

  .logo {
    height: 40px; /* Mobilde logo boyutu */
    width: auto;
  }

  .contact-button {
    position: static; /* Sabit konumu kaldır */
    margin-top: 10px; /* Altında boşluk bırak */
    width: 100%; /* Tam genişlik */
    max-width: 300px; /* Çok geniş cihazlar için sınır */
    padding: 10px 20px;
    font-size: 16px; /* Yazı boyutunu küçült */
    text-align: center; /* Metni ortala */
  }
}
