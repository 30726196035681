/* Ana stil */
.welcome-section {
  margin-top: 15px;
  display: flex; /* Flexbox kullanımı */
  flex-direction: column; /* İçerikleri dikey hizala */
  align-items: center; /* Yatay merkezleme */
  justify-content: center; /* Dikey merkezleme */
  text-align: center;
  padding: 0 20px; /* Mobil cihazlar için kenarlardan boşluk */
  color: #fdfdfd;
}

.welcome-section h1 {
  margin-bottom: 20px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 24px; /* Varsayılan font boyutu */
  color: #fdfdfd;
}

.welcome-section .welcome-text {
  max-width: 950px;
  margin: 0 auto;
  text-align: center; /* Paragrafları ortala */
  font-family: Montserrat, sans-serif;
  font-weight: 200;
  line-height: 1.5; /* Daha okunaklı olması için satır yüksekliği eklendi */
  font-size: 16px; /* Varsayılan font boyutu */
  padding: 0 10px; /* Kenarlardan boşluk */
}

/* Tablet cihazlar için medya sorgusu */
@media (max-width: 768px) {
  .welcome-section {
    margin-top: 0; /* Ekstra boşluğu kaldır */
    padding: 0 15px;
  }

  .welcome-section h1 {
    font-size: 20px; /* Başlık boyutunu küçült */
  }

  .welcome-section .welcome-text {
    font-size: 14px; /* Paragraf font boyutunu küçült */
  }
}

/* Mobil cihazlar için medya sorgusu */
@media (max-width: 780px) {
  .welcome-section {
    margin-top: 0; /* Ekstra boşluğu kaldır */
    padding: 0 10px;
  }

  .welcome-section h1 {
    font-size: 18px; /* Başlık boyutunu daha da küçült */
  }

  .welcome-section .welcome-text {
    font-size: 12px; /* Paragraf font boyutunu daha da küçült */
  }
}
