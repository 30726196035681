.footer {
  background-color: transparent; /* Şeffaf arka plan */
  padding: 20px 0;
  color: white;
  width: 100%;
  text-align: center; /* Metni ortala */
  border-top: 2px solid #7f7979; /* Üst çizgi */
  margin-top: auto; /* İçerik bittikten sonra footer'ı aşağı iter */
}

.footer-container {
  display: flex;
  justify-content: space-between; /* Logoları sol ve sağda hizala */
  align-items: center; /* Dikey hizalama */
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 40px;
}

.footer-logo {
  width: 60px; /* Logo boyutu */
}

/* Mobil uyumlu stil */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Öğeleri dikey sıralar */
    justify-content: center; /* Tüm öğeleri ortalar */
    align-items: center; /* Dikey hizalama */
    padding: 10px 20px; /* Kenarlardan içeriklere boşluk bırak */
    gap: 15px; /* Öğeler arasında boşluk */
  }

  .footer-logo {
    width: 50px; /* Logonun boyutunu küçült */
    margin-bottom: 10px; /* Logonun altında boşluk bırak */
  }

  .footer-text {
    font-size: 14px; /* Metin boyutunu küçült */
    text-align: center; /* Mobilde metni ortala */
    line-height: 1.5; /* Okunaklığı artır */
    margin-top: 10px;
  }
}
