.card-focus {
  width: 348px; /* Kartın genişliği */
  height: 344px; /* Kartın yüksekliği */
  position: relative; /* İçeriklerin konumlandırılması için relative */
  overflow: hidden; /* Kart sınırlarını aşan içerikleri gizler */
  border-radius: 10px; /* Kartın köşelerini yuvarlar */
  border: 2px solid rgba(217, 217, 217, 0.5); /* Hafif saydam bir sınır */
  background: #202020; /* Kartın arka plan rengi */
  box-sizing: border-box; /* Padding ve border'ı hesaba kat */
  transition: transform 0.3s; /* Transform hareketlerine yumuşak geçiş ekler */
}

/* Kartın içeriği için yapılandırma */
.card-content {
  position: relative; /* İçeriklerin göreceli olarak konumlandırılması */
  width: 100%; /* Kart içeriği kart genişliği kadar olur */
  height: 100%; /* Kart içeriği kart yüksekliği kadar olur */
  transition: transform 0.3s ease; /* Hareketli animasyonlar için yumuşak geçiş */
}

/* Kartın arka yüz tasarımı */
.card-back-view {
  position: absolute; /* Kartın arka yüzü ana kartın içinde konumlandırılır */
  width: 100%; /* Arka yüz genişliği tam genişlik */
  height: 100%; /* Arka yüz yüksekliği tam yükseklik */
  background: #202020; /* Arka plan rengi */
  z-index: 2; /* Kartın ön yüzünün altında yer alır */
  transition: transform 0.3s ease; /* Animasyonlar için geçiş */
  font-family: Montserrat; /* Yazı tipi */
  font-size: 14px; /* Yazı boyutu */
  color: #fff; /* Yazı rengi */
  overflow: hidden; /* İçeriğin taşmasını engeller */
  justify-content: center; /* İçeriği yatayda ortalar */
  align-items: center; /* İçeriği dikeyde ortalar */
}

/* Kart içeriği düzeni */
.card-back-content {
  width: 100%; /* İçeriğin genişliği tam olur */
  height: 100%; /* İçeriğin yüksekliği tam olur */
  padding: 15px; /* İçeriklerin kart kenarlarından boşluğu */
  box-sizing: border-box; /* Padding ve border'ı boyuta dahil eder */
  display: flex; /* Flexbox ile içerik düzeni */
  justify-content: space-between; /* Sol ve sağ içerikler arasında boşluk bırakır */
}

/* Kart başlığı */
.card-back-title {
  color: #fff; /* Yazı rengi beyaz */
  font-family: Montserrat, sans-serif; /* Yazı tipi */
  font-size: 32px; /* Yazı boyutu */
  font-weight: 400; /* Yazının kalınlığı */
  margin-bottom: 15px; /* Altında boşluk bırakır */
  max-width: 140px; /* Başlık genişliği sınırlanır */
  margin-top: 10px; /* Başlığı yukarı taşı */
}

/* İkinci kart için */
.card-container:nth-child(2) .card-back-icon {
  margin-top: 35px;
}

/* Üçüncü kart için */
.card-container:nth-child(3) .card-back-icon {
  margin-top: 70px;
}

/* Kartın sağ tarafındaki görsellerin düzeni */
.card-back-images {
  display: flex;
  flex-direction: column; /* Görselleri sırayla diz */
  gap: 10px;
  justify-content: flex-end; /* Görselleri aşağı hizalar */
  height: 95%; /* Tam yükseklik için ayarlanır */
}

/* Kartın sol alt kısmındaki logo */
.card-back-leftimage {
  position: absolute; /* Konumu belirli */
  bottom: 15px; /* Kartın alt kısmından mesafesi */
  left: 15px; /* Kartın sol kenarından mesafesi */
  z-index: 2; /* Üst katmanda yer alır */
}

/* Logo resmi */
.card-back-leftimage img {
  max-width: 80px; /* Logo genişliği */
  height: auto; /* Oranı koruyarak yüksekliği ayarlar */
}

/* Hover olduğunda kartın arka yüzü yukarı kayar */
.card-focus:hover .card-back-view {
  transform: translateY(-100%); /* Arka yüz tamamen yukarı çıkar */
}

/* Hover olduğunda kartın ön yüzü görünür */
.card-focus:hover .card-hover-view {
  transform: translateY(-100%); /* Ön yüz tamamen görünür olur */
}

/* Kartın hover ile ön yüz tasarımı */
.card-hover-view {
  position: absolute; /* Konumu belirli */
  width: 100%; /* Genişlik tam ekran */
  height: 100%; /* Yükseklik tam ekran */
  top: 100%; /* Alt taraftan başlar */
  left: 0; /* Sol kenardan başlar */
  background: #202020; /* Arka plan rengi */
  z-index: 1; /* Arka yüzün altında yer alır */
  transition: transform 0.3s ease; /* Animasyonlar için geçiş */
  padding: 5px 28px; /* İçeriklerin kart kenarlarından boşluğu */
  box-sizing: border-box; /* Padding ve border'ı dahil eder */
  text-align: left; /* Yazıları sola hizalar */
  overflow: hidden; /* Taşan içerikler kaydırılabilir */
}

/* Hover görünüm başlığı */
.card-hover-view h4 {
  color: #fff; /* Yazı rengi beyaz */
  font-family: Montserrat; /* Yazı tipi */
  font-size: 14px; /* Yazı boyutu */
  font-weight: 600; /* Yazının kalınlığı */
  width: 292px; /* Başlığın genişliği */
  margin-bottom: 5px; /* Alt boşluk */
}

/* Hover görünüm açıklama metni */
.card-hover-view p {
  color: #fff; /* Yazı rengi beyaz */
  font-family: Montserrat; /* Yazı tipi */
  font-size: 13px; /* Yazı boyutu */
  font-weight: 300; /* Yazının kalınlığı */
  width: 292px; /* Açıklama genişliği */
}

/* Sosyal medya bağlantıları düzeni */
.social-links {
  display: flex; /* Flexbox düzeni */
  justify-content: flex-start; /* Sol hizalı */
  gap: 15px; /* Linkler arasında boşluk */
  margin-top: 10px; /* Üst boşluk */
}

/* Sosyal medya ikonları */
.social-icon {
  color: #d9d9d980; /* İkon rengi */
  font-size: 1.5rem; /* İkon boyutu */
  transition: color 0.3s ease, transform 0.3s ease; /* Animasyon */
}

.social-icon:hover {
  color: #fff; /* Hover üzerinde renk değişimi */
  transform: scale(1.1); /* Hover üzerinde büyüme efekti */
}

/* Logo tasarımı */
.logos {
  display: flex; /* Flexbox düzeni */
  justify-content: flex-start; /* Sol hizalı */
  align-items: center; /* Dikeyde ortalı */
  gap: 5px; /* Logo ve diğer elemanlar arasında boşluk */
  margin-top: 10px; /* Üst boşluk */
}

/* Hover görünümdeki logo */
.hover-logo {
  max-width: 80px; /* Logo genişliği */
  height: auto; /* Oranı koruyarak yüksekliği ayarlar */
}
/* Varsayılan logo */
.default-logo {
  max-width: 80px; /* Logo genişliği */
  max-height: 90px; /* Logo yüksekliği */
  position: absolute; /* Konumu belirli */
  top: 250px; /* Kartın üstünden uzaklık */
  left: 240px; /* Kartın sol kenarından uzaklık */
}
.card-button-outside {
  display: inline-flex; /* Flex düzeni */
  width: 200px; /* Sabit genişlik */
  height: 40px; /* Sabit yükseklik */
  justify-content: center; /* İçeriği yatayda ortalar */
  align-items: center; /* İçeriği dikeyde ortalar */
  font-size: 15px; /* Yazı boyutu */
  font-family: Montserrat, sans-serif; /* Yazı tipi */
  font-weight: 400; /* Yazının kalınlığı */
  color: #fff; /* Yazı rengi */
  background-color: transparent; /* Arka plan saydam */
  border: 1px solid #fff; /* Beyaz sınır */
  border-radius: 10px; /* Yuvarlatılmış köşeler */
  cursor: pointer; /* Buton işaretçisi */
  overflow: hidden; /* Taşan içeriği gizle */
  text-overflow: ellipsis; /* Taşan metni üç nokta ile göster */
  transition: all 0.3s ease; /* Animasyon geçişleri */
  white-space: nowrap; /* Yazının tek satırda kalmasını sağlar */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* İlk durumda hafif bir gölge */
}

.card-button-outside:hover {
  background-color: #ffffff;
  color: #202020;
  transform: translateY(-6px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.card-button-outside:active {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Mobil uyarlama */
@media (max-width: 768px) {
  .card-button-outside {
    width: 100%; /* Mobilde tam genişlik */
    max-width: 300px; /* Çok geniş ekranlar için sınırlama */
    height: auto; /* Yüksekliği içerik yüksekliğine göre ayarla */
    padding: 10px 20px; /* İç dolguyu artır */
    font-size: 14px; /* Yazı boyutunu biraz küçült */
    text-align: center; /* Metni ortala */
  }
}

@media (max-width: 1130px) {
  /* Telefonlar için daha küçük düzenlemeler */
  .default-logo {
    top: 257px; /* Telefonlarda daha fazla aşağı */
  }
}
