.App {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Sayfanın minimum yüksekliği */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* İçeriği yukarı hizala */
  align-items: center;
  color: white;
  overflow: hidden; /* Taşan içerikleri gizle */
}

.background-video {
  position: fixed; /* Sabit arka plan için fixed kullan */
  top: 0;
  left: 0;
  width: 100vw; /* Tam genişlik */
  height: 100vh; /* Tam yükseklik */
  z-index: -1; /* Video arka planda */
  overflow: hidden;
}

.background-video video {
  width: 100vw; /* Video genişliği tam ekran */
  height: 100vh; /* Video yüksekliği tam ekran */
  object-fit: cover; /* Alanı doldur, taşma durumunda kes */
  pointer-events: none; /* Videoya tıklanamaz */
}
